//multiple-date-range
.multiple-date-range {
  display: grid;
  grid-template-columns: 1fr 5fr 5fr;
  align-items: center;
}

.multiple-date-range .rdt .rdtDays {
  font-size: small;
}

.multiple-date-range .rdt .rdtDay {
  border-radius: 50%;
  padding: 5px;
}

.multiple-date-range .rdt input {
  font-size: small;
  padding: 13.5px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  width: 100%;
}

.multiple-date-range .mdt-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 21.2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
}

.multiple-date-range .mdt-input-left input {
  border-radius: 0px;
}

.multiple-date-range .mdt-input-right input {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}


.custom_select {
  label {
    color: rgb(255, 255, 255) !important;


  }

  margin: 0px 8px !important;
  width: 150px;
}

.custom_flex {
  display: flex;
  justify-content: end;


}

.custom_button {

  margin: 0px 8px !important;
}

.between {
  justify-content: space-around !important;
  margin-top: 15px;
  border-top: 1px solid #ccc;
}

.outlinebtn {
  border: 1px solid #ccc !important;
  ;
}

.bpopbg {
  background-color: #f1f1f1;
}

.cusmar {
  margin: 0px 5px !important;
}

.cusmar1 {
  margin-top: 30px !important;
  border-top: 3px dashed #dbdbdb;
  padding-top: 40px;
}


.ends {
  display: flex !important;
  justify-content: end !important;
}

div#demo-simple-select {
  color: white;
}



